import DocxTemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils';
import { saveAs } from 'file-saver';
import expressions from 'angular-expressions';
import ImageModule from 'open-docxtemplater-image-module';
import haccp from '../sablon/sablon.docx';
import generateTemplate from './generateTemplate';
import store from '../redux/store';
import {
  GENERATE_DOC_REQUEST,
  GENERATE_DOC_END,
} from '../redux/actions/actionTypes';

function angularParser(tag) {
  if (tag === '.') {
    return {
      get(s) {
        return s;
      },
    };
  }
  const expr = expressions.compile(tag.replace(/(’|“|”|‘)/g, "'"));
  return {
    get(s) {
      return expr(s);
    },
  };
}

function base64DataURLToArrayBuffer(dataURL) {
  const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return false;
  }
  const stringBase64 = dataURL.replace(base64Regex, '');
  let binaryString;
  if (typeof window !== 'undefined') {
    binaryString = window.atob(stringBase64);
  } else {
    binaryString = Buffer.from(stringBase64, 'base64').toString('binary');
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}

const imageOpts = {
  getImage(tagValue) {
    return base64DataURLToArrayBuffer(tagValue.src);
  },
  getSize(img, tagValue) {
    return tagValue.size;
  },
  centered: true,
};

export default function generateDocx() {
  store.dispatch({ type: GENERATE_DOC_REQUEST });
  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
  loadFile(haccp, (error, content) => {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new DocxTemplater()
      .attachModule(new ImageModule(imageOpts))
      .loadZip(zip)
      .setOptions({ parser: angularParser });
    console.log(doc);
    doc.setData(generateTemplate());
    try {
      doc.render();
    } catch (err) {
      const e = {
        message: err.message,
        name: err.name,
        stack: err.stack,
        properties: err.properties,
      };
      console.log(JSON.stringify({ error: e }));
      throw err;
    }
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      compression: 'DEFLATE',
    });
    saveAs(out, 'output.docx');
    store.dispatch({ type: GENERATE_DOC_END });
  });
}
