import { combineReducers } from 'redux';
import clientData from './clientData';
import haccpData from './haccpData';
import saveClient from './saveClient';
import saveHaccp from './saveHaccp';
import getClients from './getClients';
import selectedClient from './selectedClient';
import getHaccps from './getHaccps';
import selectedHaccp from './selectedHaccp';
import generateDoc from './generateDoc';

export default combineReducers({
  clientData,
  haccpData,
  saveClient,
  saveHaccp,
  getClients,
  selectedClient,
  getHaccps,
  selectedHaccp,
  generateDoc,
});
