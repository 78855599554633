import React from 'react';
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import FixedBtnPanel from './FixedBtnPanel';
import Form from './Form';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#320670',
    },
    secondary: {
      main: '#edb417',
    },
  },
});

const useStyles = makeStyles(theme => ({
  container: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

function App() {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.container}>
        <Form />
        <FixedBtnPanel />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
