import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
  CHANGE_CLIENT_DATA,
  CHANGE_HACCP_DATA,
} from '../../redux/actions/actionTypes';

function getISODate(ISOString) {
  return ISOString.slice(0, 10);
}

const useStyles = makeStyles(() => ({
  Input: {
    padding: '10px 12px',
  },
  InputLabelRoot: {
    transform: 'translate(14px, 13px) scale(1)',
    display: 'block',
  },
  date: {
    display: 'block',
  },
}));

export default function TextInput({ name, title, haccp }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const value = useSelector(
    state => state[haccp ? 'haccpData' : 'clientData'][name]
  );
  return (
    <TextField
      id={`outlined-datefield-${name}`}
      label={title}
      type="date"
      margin="dense"
      variant="outlined"
      value={value || getISODate(new Date().toISOString())}
      className={classes.date}
      InputProps={{ classes: { input: classes.Input } }}
      InputLabelProps={{
        shrink: true,
        classes: { root: classes.InputLabelRoot },
      }}
      onChange={e =>
        dispatch({
          type: haccp ? CHANGE_HACCP_DATA : CHANGE_CLIENT_DATA,
          payload: { [name]: e.target.value },
        })
      }
    />
  );
}
