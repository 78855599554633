import React from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import AddAPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import DeleteIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Compress from 'compress.js';
import {
  CHANGE_CLIENT_DATA,
  CHANGE_HACCP_DATA,
} from '../../redux/actions/actionTypes';

function getBase64Image(img) {
  const canvas = document.createElement('canvas');
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const dataURL = canvas.toDataURL('image/jpeg');
  return dataURL;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(),
  },
  button: {
    marginTop: theme.spacing(),
  },
  input: {
    display: 'none',
  },
  imgCont: {
    margin: 20,
    maxWidth: 500,
    position: 'relative',
  },
  img: {
    width: '100%',
  },
  deleteIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 42,
    padding: 5,
    color: 'rgba(255,255,255,0.85)',
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: 4,
    cursor: 'pointer',
  },
}));

export default function ImageInput({ name, haccp, parent = [] }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const value = useSelector(
    state => state[haccp ? 'haccpData' : 'clientData'][name],
  );

  const handleChange = e => {
    const files = [...e.target.files];
    if (files && files.length) {
      const compress = new Compress();
      compress
        .compress(files, {
          quality: 0.9,
          maxWidth: 600,
          maxHeight: 840,
          resize: true,
        })
        .then(([img]) => {
          dispatch({
            type: haccp ? CHANGE_HACCP_DATA : CHANGE_CLIENT_DATA,
            payload: {
              [name]: { src: `${img.prefix}${img.data}` },
            },
            parent,
          });
        });
    }
  };

  const handleImageLoaded = e => {
    const img = e.target;
    const imgObj = {};
    if (value.src && value.src.slice(0, 5) !== 'data:') {
      imgObj.src = getBase64Image(img);
    }
    if (!value.size) {
      imgObj.size = [img.naturalWidth, img.naturalHeight];
    }
    console.log(imgObj);
    if (Object.keys(imgObj).length)
      dispatch({
        type: haccp ? CHANGE_HACCP_DATA : CHANGE_CLIENT_DATA,
        payload: {
          [name]: { ...value, ...imgObj },
        },
        parent,
      });
  };

  const handleDelete = () => {
    dispatch({
      type: haccp ? CHANGE_HACCP_DATA : CHANGE_CLIENT_DATA,
      payload: {
        [name]: null,
      },
      parent,
    });
  };

  return (
    <>
      <label htmlFor={`image-input-${name}`}>
        <Button
          variant="contained"
          component="span"
          className={classes.button}
          fullWidth
        >
          <AddAPhotoIcon className={classes.icon} />
          Alaprajz feltöltése
        </Button>
        <input
          accept="image/*"
          className={classes.input}
          id={`image-input-${name}`}
          type="file"
          onChange={handleChange}
        />
      </label>
      {value && (
        <div className={classes.imgCont}>
          <img
            src={value.src}
            alt="preview"
            id="preview"
            crossOrigin="anonymous"
            className={classes.img}
            onLoad={handleImageLoaded}
          />
          <DeleteIcon className={classes.deleteIcon} onClick={handleDelete} />
        </div>
      )}
    </>
  );
}
