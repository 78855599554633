import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import setHaccp from '../redux/actions/setHaccp';
import saveHaccp from '../redux/actions/saveHaccp';

const useStyles = makeStyles(theme => ({
  InputLabelRoot: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  select: {
    width: 154,
    padding: '11px 14px',
    '&:focus': {
      background: '#fafafa',
    },
  },
  wrapper: {
    marginLeft: 40,
    position: 'relative',
    [theme.breakpoints.down(400)]: {
      marginLeft: 5,
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function SelectHaccp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const haccps = useSelector(state => state.getHaccps.response);
  const selected = useSelector(state => state.selectedHaccp);
  const telepnev = useSelector(state => state.clientData.telepnev);
  const isFetchingSave = useSelector(state => state.saveHaccp.isFetching);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = e => {
    const id = e.target.value;
    dispatch(setHaccp(id));
  };

  const save = () => {
    dispatch(saveHaccp());
  };

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      className={classes.formControl}
    >
      <InputLabel
        classes={{
          root: classes.InputLabelRoot,
        }}
        ref={inputLabel}
        htmlFor="select-haccp"
      >
        Haccp
      </InputLabel>
      <Select
        value={selected}
        classes={{
          select: classes.select,
        }}
        onChange={handleChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="select-haccp"
            id="select-haccp"
          />
        }
      >
        <MenuItem value="new_haccp">Új</MenuItem>
        {haccps.map((h, i) => (
          <MenuItem value={h.id} key={h.id}>
            {`${telepnev || 'Névtelen'} - ${i + 1}`}
          </MenuItem>
        ))}
      </Select>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={isFetchingSave}
          startIcon={<SaveIcon />}
          onClick={save}
        >
          Mentés
        </Button>
        {isFetchingSave && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </FormControl>
  );
}
