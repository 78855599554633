import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import form from '../resources/clientForm.json';
import TextInput from './formfields/TextInput';
import SelectInput from './formfields/SelectInput';
import DateInput from './formfields/DateInput';
import CheckboxBlock from './formfields/CheckboxBlock';
import ImageInput from './formfields/ImageInput';
import SelectClient from './SelectClient';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 60,
  },
  title: {
    fontWeight: 'bold',
    marginTop: '30px',
    marginBottom: '10px',
  },
}));

const components = {
  TextInput,
  SelectInput,
  DateInput,
  CheckboxBlock,
  ImageInput,
};

export default function ClientDataForm() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Ügyféladatok
      </Typography>
      <SelectClient />
      {Object.keys(form).map(key => {
        const Component = components[form[key].type];
        return <Component key={key} {...form[key]} />;
      })}
    </div>
  );
}
