import React from 'react';
import Divider from '@material-ui/core/Divider';
import ClientDataForm from './ClientDataForm';
import HaccpForm from './HaccpForm';

function Form() {
  return (
    <>
      <ClientDataForm />
      <Divider />
      <HaccpForm />
    </>
  );
}

export default Form;
