import { batchActions } from 'redux-batched-actions';
import {
  GET_HACCP_DATA_REQUEST,
  GET_HACCP_DATA_SUCCESS,
  GET_HACCP_DATA_FAILURE,
  SET_SELECTED_HACCP,
  GET_HACCPS_SUCCESS,
} from './actionTypes';
import baseUrl from '../../resources/api';

export default function(id, clientId) {
  if (!id) return null;
  if (id === 'new_haccp') return { type: SET_SELECTED_HACCP, payload: id };
  let url = `${baseUrl}/haccp/${id}`;
  if (clientId) url += `?clientId=${clientId}`;
  return dispatch => {
    dispatch({ type: GET_HACCP_DATA_REQUEST });
    return fetch(url, {
      method: 'get',
      mode: 'cors',
    })
      .then(response => {
        console.log(response);
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(response => {
        console.log(response);
        dispatch(
          batchActions(
            [
              {
                type: GET_HACCP_DATA_SUCCESS,
                data: JSON.parse(
                  response?.[0]?.[0]?.data || response?.[0]?.data
                ),
              },
              { type: SET_SELECTED_HACCP, payload: id },
              response?.[1]
                ? {
                    type: GET_HACCPS_SUCCESS,
                    response: response[1],
                  }
                : {},
            ],
            'SET_HACCP_BATCH'
          )
        );
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: GET_HACCP_DATA_FAILURE, error });
      });
  };
}
