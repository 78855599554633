import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import getClients from '../redux/actions/getClients';
import setClient from '../redux/actions/setClient';
import saveClient from '../redux/actions/saveClient';

const useStyles = makeStyles(theme => ({
  InputLabelRoot: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  select: {
    width: 154,
    padding: '11px 14px',
    '&:focus': {
      background: '#fafafa',
    },
  },
  spinner: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 80,
  },
  wrapper: {
    marginLeft: 40,
    position: 'relative',
    [theme.breakpoints.down(400)]: {
      marginLeft: 5,
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function SelectClient() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const clients = useSelector(state => state.getClients.response);
  const selected = useSelector(state => state.selectedClient);
  const isFetching = useSelector(state => state.getClients.isFetching);
  const isFetchingSave = useSelector(state => state.saveClient.isFetching);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);
    dispatch(getClients());
  }, [dispatch]);

  const handleChange = e => {
    const id = e.target.value;
    dispatch(setClient(id));
  };

  const save = () => {
    dispatch(saveClient());
  };

  if (isFetching)
    return <CircularProgress size={26} className={classes.spinner} />;

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      className={classes.formControl}
    >
      <InputLabel
        classes={{
          root: classes.InputLabelRoot,
        }}
        ref={inputLabel}
        htmlFor="select-client"
      >
        Ügyfél
      </InputLabel>
      <Select
        value={selected}
        classes={{
          select: classes.select,
        }}
        onChange={handleChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="select-client"
            id="select-client"
          />
        }
      >
        <MenuItem value="new_client">Új</MenuItem>
        {clients &&
          clients.map(c => (
            <MenuItem value={c.id} key={c.id}>
              {`${c.cegnev || ''} - ${c.telepnev || ''}`}
            </MenuItem>
          ))}
      </Select>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={isFetchingSave}
          startIcon={<SaveIcon />}
          onClick={save}
        >
          Mentés
        </Button>
        {isFetchingSave && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </FormControl>
  );
}
