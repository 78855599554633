import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  CHANGE_HACCP_DATA,
  CHANGE_CLIENT_DATA,
} from '../../redux/actions/actionTypes';
import TextInput from './TextInput';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginTop: '30px',
  },
  CheckboxBlock: {
    marginLeft: '30px',
    alignItems: 'flex-start',
  },
}));

function CheckboxBlock({
  name,
  title,
  options,
  egyeb,
  withTitle,
  haccp,
  parent = [],
}) {
  const classes = useStyles();

  if (!options) {
    return (
      <FormGroup
        classes={{
          root: classes.CheckboxBlock,
        }}
      >
        <SingleCheckbox name={name} haccp={haccp} parent={[...parent, name]} />
      </FormGroup>
    );
  }

  return (
    <>
      {withTitle && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}
      <div className={classes.CheckboxBlock}>
        <FormGroup>
          {Object.keys(options).map(key => (
            <React.Fragment key={key}>
              {options[key].type === 'TextInput' ? (
                <TextInput
                  title={options[key].title}
                  name={options[key].name}
                  haccp={haccp}
                  parent={[...parent, name]}
                />
              ) : (
                <SingleCheckbox
                  name={options[key].name}
                  title={options[key].title}
                  haccp={haccp}
                  parent={[...parent, name]}
                />
              )}
              {options[key].options && (
                <CheckboxBlock
                  options={options[key].options}
                  name={options[key].name}
                  haccp={haccp}
                  egyeb={options[key].egyeb}
                  parent={[...parent, name]}
                />
              )}
            </React.Fragment>
          ))}
        </FormGroup>
        {egyeb && (
          <TextInput
            title="Egyéb"
            name={`${name}_egyeb`}
            haccp={haccp}
            parent={[...parent, name]}
          />
        )}
      </div>
    </>
  );
}

function SingleCheckbox({ name, title, haccp, parent }) {
  const dispatch = useDispatch();
  const value = useSelector(
    state => state[haccp ? 'haccpData' : 'clientData'][name]
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value || false}
          onChange={e => {
            dispatch({
              type: haccp ? CHANGE_HACCP_DATA : CHANGE_CLIENT_DATA,
              payload: { [name]: e.target.checked },
              parent,
            });
          }}
          color="primary"
        />
      }
      label={title || ''}
    />
  );
}

export default CheckboxBlock;
