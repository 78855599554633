import { batchActions } from 'redux-batched-actions';
import {
  SET_SELECTED_CLIENT,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA_FAILURE,
  RESET_CLIENT,
  RESET_HACCP,
  GET_HACCPS_SUCCESS,
  SET_SELECTED_HACCP,
  GET_HACCP_DATA_SUCCESS,
  GET_CLIENTS_SUCCESS,
} from './actionTypes';
import baseUrl from '../../resources/api';

export default function(id) {
  if (!id) return null;
  if (id === 'new_client')
    return batchActions(
      [
        { type: SET_SELECTED_CLIENT, payload: id },
        { type: SET_SELECTED_HACCP, payload: 'new_haccp' },
        { type: GET_HACCPS_SUCCESS, response: [] },
        { type: RESET_CLIENT },
        { type: RESET_HACCP },
      ],
      'SET_NEW_CLIENT_BATCH'
    );
  return dispatch => {
    dispatch({ type: GET_CLIENT_DATA_REQUEST });
    return fetch(`${baseUrl}/client/${id}`, {
      method: 'get',
      mode: 'cors',
    })
      .then(response => {
        console.log(response);
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(response => {
        console.log(response);
        dispatch(
          batchActions(
            [
              { type: SET_SELECTED_CLIENT, payload: id },
              {
                type: GET_CLIENTS_SUCCESS,
                response: response[0],
              },
              {
                type: GET_CLIENT_DATA_SUCCESS,
                data: JSON.parse(response[1][0].data),
              },
              {
                type: GET_HACCPS_SUCCESS,
                response: response[2],
              },
              {
                type: SET_SELECTED_HACCP,
                payload: response[2].length
                  ? response[2][response[2].length - 1].id
                  : 'new_haccp',
              },
              response?.[3]?.[0]?.data
                ? {
                    type: GET_HACCP_DATA_SUCCESS,
                    data: JSON.parse(response[3][0].data),
                  }
                : {},
            ],
            'SET_CLIENT_BATCH'
          )
        );
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: GET_CLIENT_DATA_FAILURE, error });
      });
  };
}
