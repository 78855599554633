import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import throttle from 'lodash.throttle';
import { enableBatching } from 'redux-batched-actions';
import rootReducer from './reducers';
import { loadState, saveState } from '../utils/localStorage';

const persistedState = loadState();

const store = createStore(
  enableBatching(rootReducer),
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 5000)
);

export default store;
